import { Dictionary } from './global.model'

export enum facilityAddOns {
  SUGGESTED_CONTENT = 'SUGGESTED_CONTENT',
  FAVOURITES = 'FAVOURITES',
  FAMILY = 'FAMILY',
  DEMENTIA = 'DEMENTIA',
  TEMPORARY_LOCK = 'TEMPORARY_LOCK',
  WHO_IS_LISTENING = 'WHO_IS_LISTENING',
  TV = 'TV',
  TV_REMOTE_CONTROL = 'TV_REMOTE_CONTROL',
}

export const addonsViewValues = {
  SUGGESTED_CONTENT: 'Suggested Content',
  FAVOURITES: 'Favourites',
  FAMILY: 'Life Story',
  DEMENTIA: 'Dementia',
  TEMPORARY_LOCK: 'Temporary Lock',
  WHO_IS_LISTENING: 'Who is Listening',
  TV: 'TV',
  TV_REMOTE_CONTROL: 'Remote TV Control',
}


export const availableLicenses: Dictionary<string> = {
  0: 'Demo plan',
  1: '5 streams',
  2: '10 streams',
  3: '15 streams',
  4: '20 streams',
  5: '25 streams'
}